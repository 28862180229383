import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Components/Home";
import Browse from "./Components/Browse";
import Footer from "./Components/Footer";
import Upcoming from "./Components/Upcoming";
import Subscribe from "./Components/Subscribe";
import MediaLanding from "./Components/Media/MediaLanding";
import Player from "./Components/Player/Player";
import Header from "./Components/Header";
import Login from "./Components/User/Login";
import Details from "./Components/Details";
import Support from "./Components/FooterPages/Support";
import CookiePolicy from "./Components/FooterPages/CookiePolicy";
import Privacy from "./Components/FooterPages/Privacy";
import TermsConditions from "./Components/FooterPages/Terms&Conditions";
import RefundPolicy from "./Components/FooterPages/RefundPolicy";
import AboutUs from "./Components/FooterPages/AboutUs";
import ContactUs from "./Components/FooterPages/ContactUs";
import Profile from "./Components/User/Profile";
import { useEffect, useState } from "react";
import AppSettingPage from "./Components/FooterPages/AppSettingPage";
import { UserProvider } from "./UserContext";
import { useCookies } from "react-cookie";
import instance from "./Components/common/Api";
import VideoJsPlayer from "./Components/Player/VideoJsPlayer";
import ShowMore from "./Components/HomeComponents/ShowMore";
import ForgotPassword from "./Components/User/ForgotPassword";
import { generateToken, messaging } from "./firebase";
import Location from "./Components/FooterPages/Location";
import VideoPlayerHLS from "./Components/Player/TempVideoPlayer";
// import firebaseApp from "./firebase";
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import AndroidPage from "./Components/common/AndroidPage";
import DeleteUserPage from "./Components/FooterPages/DeleteUserPage";
import Subscription from "./Components/Subscription";
import QuickSubscription from "./Components/QuickSubscription";
import PaymentStatusPage from "./Components/PaymentStatusPage";
import Explayer from "./Components/Explayer";

const App = () => {
  // console.warn("Firebase in app JS");
  // console.warn(firebaseApp);

  // const firebaseConfig = {
  //   // apiKey: "AIzaSyBtGhDmLdhIBJb97d81dVknjx9TgUyAlZs",
  //   // authDomain: "kooku-bd1b8.firebaseapp.com",
  //   // databaseURL: "https://kooku-bd1b8.firebaseio.com",
  //   // projectId: "kooku-bd1b8",
  //   // storageBucket: "kooku-bd1b8.appspot.com",
  //   // messagingSenderId: "619839761777",
  //   // appId: "1:619839761777:web:2ab0443cced018f8c4dad0",
  //   // measurementId: "G-3ZJNFSXB7B",
  //   apiKey: "AIzaSyC6LJwOCz59yI_wZ1N3BGVUzJ7fzrkT4o4",
  //   authDomain: "demobebu.firebaseapp.com",
  //   projectId: "demobebu",
  //   storageBucket: "demobebu.appspot.com",
  //   messagingSenderId: "836912395201",
  //   appId: "1:836912395201:web:a68c71acd67c1941243540",
  // };

  // const firebaseApp = initializeApp(firebaseConfig);
  // const messaging = getMessaging(firebaseApp);

  // const generateToken = async () => {
  //   const permission = await Notification.requestPermission();
  //   // console.warn(permission);
  //   if (permission === "granted") {
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BI5XkoqDAQniFBDKgc2ocE0Lar95D9uDC_bOPtJjarHJUN-iYPmU7PZ9P2G_S2M_gJYElUk47Y1iZlpz4DZmZFw",
  //     });
  //     // console.log(token);
  //     updateUser(token);
  //     // localStorage.setItem("NewfcmOlaple", JSON.stringify(token));
  //   }
  // };

  const backgroundImageUrl = "https://www.bebu.app/assets/img/bg.svg";
  const [newUser, setNewUser] = useState(null);
  const [hideFooter, setHideFooter] = useState(false);
  const [userserIP, setUserIP] = useState(null);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1200px)").matches;
  }
  //https://www.bebu.app/logout-bg.png
  // const [showNotificationBanner, setShowNotificationBanner] = useState(
  //   Notification.permission === "default"
  // );
  const FetchUser = () => {
    const storedUserData = localStorage.getItem("OlapleAppUser");
    const storedSocial = localStorage.getItem("SocialLogin");
    // ocalStorage.setItem("SocialLogin", true);
    return storedUserData ? JSON.parse(storedUserData) : null;
  };

  const sendTokenToServer = async () => {
    if (localStorage.getItem("NewfcmOlaple") !== null) {
      const storedUserFcmToken = localStorage
        .getItem("NewfcmOlaple")
        .replace(/"/g, "");
      if (storedUserFcmToken) {
        console.log(storedUserFcmToken, "OLD");
        await updateNotification(storedUserFcmToken);
      }
    } else {
      const token = await generateToken();
      if (token) {
        // console.log(token, "NEW");
        await updateNotification(token);
      }
    }
  };

  const updateNotification = async (token) => {
    // console.log(token, "updateNotification");
    try {
      await instance.post(
        `/saveUnauthUsersDeviceDetails?id=1&FCM_TOKEN=${token}&FINGERPRINT=${window.navigator.userAgent}&DEVICE_LANGUAGE=${window.navigator.language}`
      );
      // console.log("FCM token saved on the server successfully.");
    } catch (error) {
      console.error("Error saving FCM token:", error);
    }
  };

  useEffect(() => {
    sendTokenToServer();
    function handleResize() {
      setIsMobile(isMobileDevice());
    }
    async function fetchUserIP() {
      //UPDATE IP
      const ip = await window.getUserIP();
      if (ip === "Unknown") {
        setUserIP(ip);
      } else {
        setUserIP("45.115.53.96");
      }
    }

    fetchUserIP();
    // generateToken();
    // generateToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
    });

    setNewUser(FetchUser);
    // const storedUserData = localStorage.getItem("userData");

    // if (storedUserData) {
    //   setUserData(JSON.parse(storedUserData));
    // }
    // console.log("REFRESHHHH:::", newUser);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // const updateUser = (token) => {
  //   instance
  //     .post(
  //       `/saveUnauthUsersDeviceDetails?id=1&FCM_TOKEN=${token}&FINGERPRINT=${
  //         window.navigator.userAgent
  //       }&DEVICE_LANGUAGE=${window.navigator.language}&ip=${window.getUserIP()}`
  //     )
  //     .then((response) => {
  //       // console.warn(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     })
  //     .finally(() => {});
  // };

  // const location = useLocation();
  // const hideFooterRoutes = ["/play"];
  // const shouldHideFooter = hideFooterRoutes.includes(location.pathname);

  // const svgBackground = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><circle cx='50' cy='50' r='40' fill='red'/></svg>")`;

  // const containerStyle = {
  //   backgroundImage: svgBackground,
  //   width: '200px', // Adjust width as needed
  //   height: '200px', // Adjust height as needed
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  // };

  return (
    <Router>
      <UserProvider>
        <div
          style={{
            width: "100%",
            minHeight: "55rem",
            position: "relative",
            backgroundImage: isMobile
              ? `url(./main-bg.jpg)`
              : `url(./main-bg-landscap.jpg)`,
            backgroundSize: "cover",
            // backgroundPosition: "center",
            // backgroundColor: "#141414",
            backgroundRepeat: "round",
            backgroundBlendMode: "overlay",
          }}
        >
          <Header userData={newUser} />

          <div>
            <Routes>
              <Route path="/" element={<Home ip={userserIP} />} />
              <Route
                path="/browse"
                element={
                  <Browse
                    userData={newUser}
                    ip={userserIP}
                    mobileDevice={isMobile}
                  />
                }
              />
              <Route
                path="/upcoming"
                element={
                  <Upcoming
                    userData={newUser}
                    ip={userserIP}
                    isMobile={isMobile}
                  />
                }
              />
              <Route
                path="/subscribe"
                element={<Subscribe userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/medialanding"
                element={<MediaLanding userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/play"
                element={
                  <Player
                    // toggleFooterVisibility={setHideFooter}
                    userData={newUser}
                    ip={userserIP}
                  />
                }
              />
              {!newUser && (
                <Route
                  path="/login"
                  element={<Login ip={userserIP} userData={newUser} />}
                />
              )}
              <Route
                path="/media/"
                element={<Details userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/CookiePolicy"
                element={<CookiePolicy userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/privacy"
                element={<Privacy userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsConditions userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/deletepolicy"
                element={<DeleteUserPage ip={userserIP} isMobile={isMobile} />}
              />
              <Route
                path="/support"
                element={<Support userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/refund-policy"
                element={<RefundPolicy userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/about-us"
                element={<AboutUs userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/contact-us"
                element={<ContactUs userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/media-video"
                element={<VideoJsPlayer userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/profile"
                element={<Profile userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/appsettings"
                element={<AppSettingPage userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/showmore"
                element={<ShowMore userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/forgot-password"
                element={<ForgotPassword userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/location"
                element={<Location userData={newUser} ip={userserIP} />}
              />
              <Route
                path="/success"
                element={<Home userData={newUser} ip={userserIP} />}
              />
              <Route path="/android" element={<AndroidPage />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/quick_subscribe" element={<QuickSubscription />} />
              <Route path="/payment_status" element={<PaymentStatusPage />} />
              <Route path="/explayer" element={<Explayer />} />
            </Routes>
          </div>

          {!hideFooter && <Footer ip={userserIP} />}
        </div>
      </UserProvider>
    </Router>
  );
};

export default App;
