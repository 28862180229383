import React, { useEffect, useState } from "react";
import "./Location.css";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  ModalHeader,
  Row,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FaAngleRight, FaHeadset } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import instance from "../common/Api";

const Location = (props) => {
  const [countryNames, setCountryNames] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [newCountry, setNewCountry] = useState("");
  const handleModelClose = () => {
    setShowModel(false);
  };

  const updateCountryName = (name) => {
    if (name !== null) {
      instance
        .post(`/updateUserCountry?id=${props.userData.id}&countryName=${name}`)
        .then((response) => {
          // console.warn(" fetching data:", response.data.country);
          if (response.data.success) {
            // setNewCountry(response.data.country);
            alert(response.data.message);
          }
          // setBrowseData(response.data.content); //((prevData) => [...prevData, ...response.data.content]); // Append new data to existing data
          // setOriginalBrowseData(response.data.content);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    // console.warn(props);
    instance
      .post(`/getUserCountry?id=${props.userData.id}`)
      .then((response) => {
        // console.warn(" fetching data:", response.data.country);
        if (response.data.success) {
          setNewCountry(response.data.country);
        }
        // setBrowseData(response.data.content); //((prevData) => [...prevData, ...response.data.content]); // Append new data to existing data
        // setOriginalBrowseData(response.data.content);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {});

    const fetchData = async () => {
      try {
        const response = await fetch("/countries.json"); // Assuming countries.json is in the public directory
        const data = await response.json();
        const names = data.map((country) => country.name);
        setCountryNames(names);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchData();
  }, []);

  const handleItemClick = (item) => {
    console.log(item);
    setNewCountry(item);
    setShowModel(false);
    updateCountryName(item);
  };
  return (
    <div
      style={{
        width: "100%",
        minHeight: "50rem",
        position: "relative",
        backgroundImage: `url(./main-bg.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",

        backgroundBlendMode: "overlay",
      }}
    >
      <Card
        body
        style={{
          backgroundColor: "#3F7EFF",
          borderRadius: "1rem",
          marginTop: "5rem",
          marginLeft: "1rem",
          marginRight: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
          onClick={() => setShowModel(true)}
        >
          <span>&nbsp; &nbsp; Select Country</span>
          <FaAngleRight style={{ marginLeft: "auto" }} />
        </div>
      </Card>
      <Card
        body
        style={{
          backgroundColor: "#EB4328",
          borderRadius: "1rem",
          marginTop: "0rem",
          marginLeft: "1rem",
          marginRight: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "white",
            height: "10px",
          }}
        >
          <FaLocationDot />
          <span>&nbsp; &nbsp; {newCountry}</span>
        </div>
      </Card>
      <Modal
        show={showModel}
        onHide={handleModelClose}
        style={{ marginTop: "10rem" }}
      >
        <ModalHeader
          style={{
            padding: "0",
            backgroundColor: "white",
            borderBottom: "none",
          }}
        >
          <h6
            className="text-black"
            style={{
              marginTop: "10px",
              marginLeft: "5px",
            }}
          >
            Select Country
          </h6>
        </ModalHeader>
        <Modal.Body
          style={{
            color: "black",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              maxHeight: "300px", // Set your desired fixed height here
              overflowY: "scroll",
              width: "100%", // Ensure full width
            }}
          >
            {countryNames &&
              countryNames.map((item, idx) => (
                <p
                  key={idx}
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() => handleItemClick(item)}
                >
                  {item}
                </p>
              ))}
          </div>

          <Button
            variant="secondary"
            style={{
              marginTop: "20px",
              color: "white",
              backgroundColor: "#EB4328",
            }}
            onClick={handleModelClose}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Location;
