import "./Cookies.css";
import React from "react";

const AboutUs = () => {
  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundImage: `url(./main-bg-landscap.jpg)`,
        backgroundSize: "cover",

        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="container privacyPolicyBellowTextBox text-black">
        <div id="scroll">
          <div className="row">
            <div className="col-md-12 privacyContentBox">
              <h3
                style={{
                  color: "black",
                  paddingBottom: "10px",
                  paddingTop: "50px",
                }}
              >
                ABOUT US
              </h3>
              <p style={{ color: "black" }}>
                Weekend binges just got better! Olaple is the latest
                entertainment hub, built to get your heart racing. Watch the
                best of drama, comedy, romance and much more on the Olaple.
                Stream online on www.olaple.com We are certain you will love our
                original programming of Short Films and Web Series. The best
                part is you can try before you buy!
              </p>
              <div style={{ paddingLeft: "50px" }}>
                <p style={{ color: "black" }}>• Content in local languages</p>
                <p style={{ color: "black" }}>
                  • Unlimited and Uninterrupted Video Streaming
                </p>
                <p style={{ color: "black" }}>
                  • Offline in-app Download available
                </p>
                <p style={{ color: "black" }}>• Easy & flexible pricing</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
