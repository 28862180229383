import "./Cookies.css";
import React from "react";

const ContactUs = () => {
  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundImage: `url(./main-bg-landscap.jpg)`,
        backgroundSize: "cover",

        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            <div className="col-md-12 privacyContentBox">
              <h3
                style={{
                  color: "black",
                  paddingBottom: "10px",
                  paddingTop: "50px",
                }}
              >
                CONTACT US
              </h3>

              <h5
                style={{
                  color: "black",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                Mail us at{" "}
                <a
                  href="mailto:info@olaple.com"
                  style={{ color: "#e20788", textDecoration: "none" }}
                >
                  info@olaple.com
                </a>{" "}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
